import React from "react";
import { Link } from "react-router-dom";

export const MainMenuBar = () => {
  return (
    <div>
      <div className="ui secondary vertical menu">
        <div className="item">
          <div className="header">Portfolios</div>
          <div className="menu">
            <Link to="/Photojournalism" className="item">
              Photojournalism
            </Link>
            <Link to="/Portrait" className="item">
              Portraits
            </Link>
            <Link to="/Travel" className="item">
              Travel
            </Link>
            <Link to="/TearSheet" className="item">
              Tearsheets
            </Link>
            <Link to="/Commercial" className="item">
              Commercial
            </Link>
          </div>
        </div>
        <div className="item">
          <div className="header">Stories</div>
          <div className="menu">
            <Link to="/MigrantPage" className="item">
              Myanmar Migrants in Thailand
            </Link>
            <Link to="/PrimaryCare" className="item">
              Primary Care Cluster 4.0
            </Link>
            <Link to="/RoyalCremation" className="item">
              King Rama IX's Royal Cremation
            </Link>
            <Link to="/WaterManagement" className="item">
              Water Management
            </Link>
            <Link to="/PhraPisalVisalo" className="item">
              Phra Paisal Visalo
            </Link>
            <Link to="/AjarnPuey" className="item">
              The Centennial Anniversary of Puey Ungphakorn
            </Link>
          </div>
        </div>
        <div className="item">
          <div className="header">Commercial</div>
          <div className="menu">
            <Link to="/Corporate" className="item">
              Corporate
            </Link>
            <Link to="/Studio" className="item">
              Studio
            </Link>
            <Link to="/Interview" className="item">
              Interview
            </Link>
            <Link to="/Wedding" className="item">
              Wedding
            </Link>
          </div>
        </div>
        <div className="item">
          <div className="header">Contact</div>
          <div className="menu">
            <Link to="/Profile" className="item">
              Contact & Info
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};
