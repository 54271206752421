import { IImages } from "../actions/index";
import { ActionTypes } from "../actions/types";

// each reducer can have its own file
// this is how to assign null to the IImages type
// as we have null check in all pages
export default (images: IImages | null = null, action: any) => {
  if (action.type === ActionTypes.fetchLandingPage) {
    // console.log(`LandingpageReducer: ${action.payload}`);
    return action.payload;
  }
  return images;
};
