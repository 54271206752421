import React from "react";
import { PhotoPage } from "../component/Elements/PhotoPage";

const Portrait = () => {
  return (
    <PhotoPage
      imageBucket="Portrait"
      pageTitle="Portraits"
      animationName="redManRuningAnimation"
    />
  );
};

export default Portrait;
