import React from "react";
import Lottie from "react-lottie";
import runningData from "../../../lotties/runing.json";

const RunningAnimation = () => {
  const runningOptions = {
    loop: true,
    autoplay: true,
    animationData: runningData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <Lottie
      style={{ marginTop: "50px" }}
      options={runningOptions}
      height={100}
      width={120}
    />
  );
};

export default RunningAnimation;
