import React from "react";
import { PhotoPage } from "../component/Elements/PhotoPage";

const Wedding = () => {
  return (
    <PhotoPage
      imageBucket="Wedding"
      pageTitle="Weddings"
      animationName="redManRuningAnimation"
    />
  );
};

export default Wedding;
