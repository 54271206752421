import React from "react";
import { PhotoPage } from "../component/Elements/PhotoPage";

const Photojournalism = () => {
  return (
    <PhotoPage
      imageBucket="Photojournalism"
      pageTitle="Photojournalism"
      animationName="cameraAnimation"
    />
  );
};

export default Photojournalism;
