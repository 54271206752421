import React from "react";
import { Accordion, Dropdown, Icon } from "semantic-ui-react";
import { Link } from "react-router-dom";
import "./index.css";

export default class AccordionMenu extends React.Component {
  state = { activeIndex: 0 };

  handleClick = (e, titleProps) => {
    const { index } = titleProps;
    const { activeIndex } = this.state;
    const newIndex = activeIndex === index ? -1 : index;

    this.setState({ activeIndex: newIndex });
  };

  render() {
    const { activeIndex } = this.state;

    const PortfoliosSection = (
      <>
        <Accordion.Title
          active={activeIndex === 1}
          index={1}
          onClick={this.handleClick}
          className="accordionTitleFont"
        >
          Portfolios
          <Icon name="dropdown" />
        </Accordion.Title>
        <Accordion.Content active={activeIndex === 1}>
          <Link to="/Photojournalism" className="item">
            <p className="accordionSubTitleFont">Photojournalism</p>
          </Link>
          <Link to="/Portrait" className="item">
            <p className="accordionSubTitleFont">Portraits</p>
          </Link>
          <Link to="/Travel" className="item">
            <p className="accordionSubTitleFont">Travel</p>
          </Link>
          <Link to="/TearSheet" className="item">
            <p className="accordionSubTitleFont">Tearsheets</p>
          </Link>
          <Link to="/Commercial" className="item">
            <p className="accordionSubTitleFont">Commercial</p>
          </Link>
        </Accordion.Content>
      </>
    );

    const StorySection = (
      <>
        <Accordion.Title
          active={activeIndex === 2}
          index={2}
          onClick={this.handleClick}
          className="accordionTitleFont"
        >
          Stories
          <Icon name="dropdown" />
        </Accordion.Title>
        <Accordion.Content active={activeIndex === 2}>
          <Link to="/MigrantPage" className="item">
            <p className="accordionSubTitleFont">
              Myanmar Migrants in Thailand
            </p>
          </Link>
          <Link to="/PrimaryCare" className="item">
            <p className="accordionSubTitleFont">Primary Care Cluster 4.0</p>
          </Link>
          <Link to="/RoyalCremation" className="item">
            <p className="accordionSubTitleFont">
              King Rama IX's Royal Cremation
            </p>
          </Link>
          <Link to="/WaterManageMent" className="item">
            <p className="accordionSubTitleFont">Water Management</p>
          </Link>
          <Link to="/PhraPisalVisalo" className="item">
            <p className="accordionSubTitleFont">Phra Paisal Visalo</p>
          </Link>
          <Link to="/AjarnPuey" className="item">
            <p className="accordionSubTitleFont">
              The Centennial Anniversary of Puey Ungphakorn
            </p>
          </Link>
        </Accordion.Content>
      </>
    );

    const CommercialSection = (
      <>
        <Accordion.Title
          active={activeIndex === 3}
          index={3}
          onClick={this.handleClick}
          className="accordionTitleFont"
        >
          Commercial
          <Icon name="dropdown" />
        </Accordion.Title>
        <Accordion.Content active={activeIndex === 3}>
          <Link to="/Corporate" className="item">
            <p className="accordionSubTitleFont">Corporate</p>
          </Link>
          <Link to="/Studio" className="item">
            <p className="accordionSubTitleFont">Studio</p>
          </Link>
          <Link to="/Interview">
            <p className="accordionSubTitleFont">Interview</p>
          </Link>
          <Link to="/Wedding" className="item">
            <p className="accordionSubTitleFont">Wedding</p>
          </Link>
        </Accordion.Content>
      </>
    );

    const HomepageSection = (
      <>
        <Accordion.Title>
          <Link to="/" className="accordionTitleFont">
            Homepage
          </Link>
        </Accordion.Title>
      </>
    );

    const InfoSection = (
      <>
        <Accordion.Title>
          <Link to="/Profile" className="accordionTitleFont">
            Contact & Info
          </Link>
        </Accordion.Title>
      </>
    );

    return (
      <Dropdown icon="list ul" simple item>
        <Dropdown.Menu>
          <Accordion styled fluid>
            {HomepageSection}
            {PortfoliosSection}
            {StorySection}
            {CommercialSection}
            {InfoSection}
          </Accordion>
        </Dropdown.Menu>
      </Dropdown>
    );
  }
}
