import Slider from "react-slick";
import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./index.css";

export const ImageSlider = ({ photos }) => {
  const settings = {
    infinite: true,
    dots: false,
    autoplay: true,
    speed: 1000,
    slidesToShow: 1,
    arrow: false,
    centerMode: true,
    pauseOnHover: false,
    fade: true,
  };

  return (
    <>
      {photos != null && (
        <Slider {...settings}>
          {photos.map((photo, key) => (
            <div key={key}>
              <img src={photo.value} key={key} alt="Cannot load an image" />
            </div>
          ))}
        </Slider>
      )}
    </>
  );
};
