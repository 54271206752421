import React from "react";
import AccordionMenu from "../component/Menu/AccordionMenu";
import {
  bio,
  email,
  location,
  exhibition2015,
  exhibition2012,
  exhibition2010,
  lifeframer,
} from "./Desc";
import { TopBar } from "../component/Elements/TopBar";
import "../App.css";

export const Profile = () => {
  return (
    <div>
      <TopBar />
      <div className="photoPage">
        <AccordionMenu />
      </div>
      <div className="profilePage">
        <div className="profileImage">
          <img
            className="profileImage"
            src="images/profile.jpg"
            alt="Failed to download"
          />
        </div>
        <div className="profileContent">
          <h5>Bio</h5>
          <p className="bioText">{bio}</p>
          <h5>Exhibitions</h5>
          <p>{exhibition2015}</p>
          <p>{exhibition2012}</p>
          <p>{exhibition2010}</p>
          <h5>Others</h5>
          <p>{lifeframer}</p>
          <h5>I contribuited to...</h5>
          <ul>
            <li>Terres Des Hommes, Germany</li>
            <li>
              United National Economic and Social Commission for Asia and the
              Pacific (UN - ESCAP)
            </li>
            <li>National Geographic Magazine, Thailand</li>
            <li>Greenpeace Southeast Asia</li>
            <li>WWF Thailand</li>
            <li>Labour Protection Network Foundation</li>
            <li>Zoological Park Organization of Thailand</li>
            <li>Department of Mental Health, Ministry of Health</li>
            <li>Society and Health Institute (SHI)</li>
            <li>Simply Living Magazine </li>
            <li>Thailand Knowledge Park (TK Park)</li>
          </ul>
          <p>
            <b>Location:</b> {location}
          </p>
          <p>
            <b>Email:</b> {email}
          </p>
          <p>
            <b>Mobile:</b> +61 42 687 1634
          </p>
        </div>
      </div>
    </div>
  );
};
