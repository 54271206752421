import { useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { fetchImageCaptions, fetchImages } from "../../actions";

// TO_LEARN
// useEffect - DONE
// move network call to Actions - DONE
// call fetch caption - DONE

const FetchPhotosNew = ({ bucket }) => {
  const dispatch = useDispatch();

  // Run when a component is initially rendered and sometimes when it is rerendered
  useEffect(() => {
    dispatch(fetchImages(bucket));
    dispatch(fetchImageCaptions());
    // This 2nd argument [] controls whether to call useEffect after the initial call or not
    // [] means never call useEffect again
    // no [], means call after every rerender
    // [someValue] means call fater [someValue] changed

    // eslint-disable-next-line
  }, []);

  return null;
};

// get State from Redux and map to Props
// This is just to console.log the updated state
const mapStateToProps = (state) => {
  // console.log("FetchPhotosNew state: ", state);
  return state;
};

// The connect() function connects a React component to a Redux store.
// can replace mapStateToProps with null
export default connect(mapStateToProps, {
  fetchImages,
  fetchImageCaptions,
})(FetchPhotosNew);
