import firebase from "firebase/compat/app";
import "firebase/compat/storage";
import "firebase/compat/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyAPrDLy3kj6j0v1WV6RbC-GdsC0xvv9Fes",
  authDomain: "house-studio.firebaseapp.com",
  databaseURL: "https://house-studio.firebaseio.com",
  projectId: "house-studio",
  storageBucket: "house-studio.appspot.com",
  messagingSenderId: "48447722357",
  appId: "1:48447722357:web:00fc3052e94f26cf87393c",
  measurementId: "G-JMBH7T847W",
};

firebase.initializeApp(firebaseConfig);

export default firebase;
