export const migrantIntro =
  "Thailand has been facing, since past until now, a shortage of labour. Therefore, we have to rely more on migrant labour, mostly from Myanmar. That brings about so many stories with both positive and negative towards our society, economy, culture and human rights. We need to know and catch up on what happened.";

export const waterManagementIntro =
  "This is a documentary about water management.";

export const corporateIntro = "";
export const tearsheetsIntro = "A collection of publications";
export const primaryCareIntro = "";

export const name = "Sittichai Jittatad";
export const facebookURL = "https://www.facebook.com/sittichai.jittatad";
export const instagramURL = "https://www.instagram.com/sittichai.jittatad/";
export const linkedin = "https://www.linkedin.com/in/sittichaijittatad/";
export const email = "sittichai.jittatad@gmail.com";
export const location = "Based in Thailand and Melbourne";

export const bio =
  "Born in Thailand, my interest in photography ignited since I was in university (when I studied Environmental Science). I've started working as a photographer for several magazines and then in 2008, I was with National Geographic Magazine (Thai edition) as the photographer assistant and researcher. Now, I pursued my own work by freelancing, covering various aspects of photography genres including documentaries, photojournalism, portrait and product catalogs.";

export const exhibition2015 =
  '2015: Seminar & Photography Exhibition on "Assistance to Human Trafficking Victims: the Trafficking Myth in Thailand", supported by Labour Rights Promotion Network Foundation(LPN) and Foreign Correspondents’ Club of Thailand (FCCT)';

export const exhibition2012 =
  '2012: Documentary photography exhibition on "Myanmar Migrant Workers in Thailand", supported by Labour Rights Promotion Network Foundation (LPN) and World Vision Thailand';

export const exhibition2010 =
  '2010: Group exhibition on "Shots on Climate Change, Thailand 2010" organised by WWF Thailand, Coca Cola Foundation Thailand, and Bangkok Metropolitan Administration';

export const lifeframer =
  'October 2012: Selected amongst the winners in "FACE OF THE EARTH" photography competition, judged by Samantha Clark of National Geographic. The competition was organised by Life Framer, which is a platform for discovering, sharing and exhibiting contemporary photography.';
