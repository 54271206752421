import React from "react";
import FetchPhotosNew from "../component/FetchData/FetchPhotosNew";
import { ThumbnailPhoto } from "../component/ThumbnailPhoto";
import { connect } from "react-redux";
import AccordionMenu from "../component/Menu/AccordionMenu";
import { Slider } from "../component/CarouselDisplay/Slider";
import { TopBar } from "../component/Elements";
import { corporateIntro } from "./Desc";
import "../App.css";

/*
  class-based component
*/

interface PhotoProps {
  corporate?: any;
}

class Corporate extends React.Component<PhotoProps> {
  state = { isThumbnail: true };

  displayThumbnail = () => {
    this.setState({ isThumbnail: true });
  };

  displaySlider = () => {
    this.setState({ isThumbnail: false });
  };

  render() {
    const photoProps = {
      bucket: "Corporate",
      photos: this.props.corporate,
    };

    return (
      <div>
        {this.props.corporate === null ? (
          <FetchPhotosNew bucket="Corporate" />
        ) : null}
        <TopBar />
        <div className="photoPage">
          <div className="alignTitleAndMenuIcon">
            <AccordionMenu />
            <h3 className="photoTitle">Corporate Photography</h3>
          </div>
          <p className="photoDesc">{corporateIntro}</p>
          <div className="photoContainer">
            <i
              className="th icon iconCursor"
              style={{ marginRight: "10px" }}
              onClick={() => this.displayThumbnail()}
            ></i>
            <i
              className="images outline icon iconCursor"
              onClick={() => this.displaySlider()}
            ></i>
            {this.state.isThumbnail && this.props.corporate != null && (
              <ThumbnailPhoto {...photoProps} animationName="cameraAnimation" />
            )}
            {!this.state.isThumbnail && this.props.corporate != null && (
              <Slider {...photoProps} />
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: any) => {
  //console.log("corporate state: ", state);
  return state;
};

export default connect(mapStateToProps)(Corporate);
