import { combineReducers } from "redux";
import { ActionTypes } from "../actions/types";
import { ICaptions } from "../actions/index";
import landingpageReducer from "../reducers/LandingpageReducer";

const captionsReducer = (state: ICaptions[] = [], action: any) => {
  if (action.type === ActionTypes.fetchCaptions) {
    return action.payload;
  }
  return state;
};

const migrantsReducer = (photos = null, action: any) => {
  if (action.type === ActionTypes.fetchMigrant) {
    return action.payload;
  }
  return photos;
};

const tearsheetReducer = (photos = null, action: any) => {
  if (action.type === ActionTypes.fetchTearSheet) {
    return action.payload;
  }
  return photos;
};

const primaryCareReducer = (photos = null, action: any) => {
  if (action.type === ActionTypes.fetchPrimaryCare) {
    return action.payload;
  }
  return photos;
};

const waterManagementReducer = (photos = null, action: any) => {
  if (action.type === ActionTypes.fetchWaterManagement) {
    return action.payload;
  }
  return photos;
};

const ajarnPueyReducer = (photos = null, action: any) => {
  if (action.type === ActionTypes.fetchAjarnPuey) {
    return action.payload;
  }
  return photos;
};

const corporateReducer = (photos = null, action: any) => {
  if (action.type === ActionTypes.fetchCorporate) {
    return action.payload;
  }
  return photos;
};

const photojournalismReducer = (photos = null, action: any) => {
  if (action.type === ActionTypes.fetchPhotojournalism) {
    return action.payload;
  }
  return photos;
};

const portraitReducer = (photos = null, action: any) => {
  if (action.type === ActionTypes.fetchPortrait) {
    return action.payload;
  }
  return photos;
};

const travelReducer = (photos = null, action: any) => {
  if (action.type === ActionTypes.fetchTravel) {
    return action.payload;
  }
  return photos;
};

const royalCremationReducer = (photos = null, action: any) => {
  if (action.type === ActionTypes.fetchRoyalCremation) {
    return action.payload;
  }
  return photos;
};

const phraPaisalReducer = (photos = null, action: any) => {
  if (action.type === ActionTypes.fetchPhraPaisal) {
    return action.payload;
  }
  return photos;
};

const studioReducer = (photos = null, action: any) => {
  if (action.type === ActionTypes.fetchStudio) {
    return action.payload;
  }
  return photos;
};

const interviewReducer = (photos = null, action: any) => {
  if (action.type === ActionTypes.fetchInterview) {
    return action.payload;
  }
  return photos;
};

const commercialPageReducer = (photos = null, action: any) => {
  if (action.type === ActionTypes.fetchCommercialPage) {
    return action.payload;
  }
  return photos;
};

const weddingPageReducer = (photos = null, action: any) => {
  if (action.type === ActionTypes.fetchWeddingPage) {
    return action.payload;
  }
  return photos;
};

// To call Redux's state store from somewhere
// e.g useSelector, then call state.captions
// or use mapStateToProp, then call this.prop.captions
export default combineReducers({
  captions: captionsReducer,
  tearsheet: tearsheetReducer,
  watermanagement: waterManagementReducer,
  corporate: corporateReducer,
  photojournalism: photojournalismReducer,
  portrait: portraitReducer,
  travel: travelReducer,
  royalcremation: royalCremationReducer,
  phrapaisal: phraPaisalReducer,
  studio: studioReducer,
  interview: interviewReducer,
  commercial: commercialPageReducer,
  wedding: weddingPageReducer,
  landingpage: landingpageReducer,
  myanmarmigrants: migrantsReducer,
  primarycarecluster: primaryCareReducer,
  ajarnpuey: ajarnPueyReducer,
});
