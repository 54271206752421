import React from "react";
import { PhotoPage } from "../component/Elements/PhotoPage";

const Interview = () => {
  return (
    <PhotoPage
      imageBucket="Interview"
      pageTitle="Interview"
      animationName="redManRuningAnimation"
    />
  );
};

export default Interview;
