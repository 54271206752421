import React from "react";
import Lottie from "react-lottie";
import commingSoonData from "../../../lotties/commingSoon.json";

const CommingSoonAnimation = () => {
  const options = {
    loop: true,
    autoplay: true,
    animationData: commingSoonData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <Lottie
      style={{ marginTop: "50px" }}
      options={options}
      height={100}
      width={300}
    />
  );
};

export default CommingSoonAnimation;
