import React from "react";
import { PhotoPage } from "../component/Elements/PhotoPage";

const AjPuey = () => {
  return (
    <PhotoPage
      imageBucket="AjarnPuey"
      pageTitle="The Centennial Anniversary of Puey Ungphakorn"
      animationName="manRuningAnimation"
    />
  );
};

export default AjPuey;
