import React from "react";
import MigrantPage from "./pages/MigrantPage";
import WaterManagement from "./pages/WaterManagement";
import Tearsheets from "./pages/TearSheet";
import Photojournalism from "./pages/Photojournalism";
import PrimaryCare from "./pages/PrimaryCare";
import Corporate from "./pages/Corporate";
import Homepage from "./pages/Homepage";
import Portrait from "./pages/Portrait";
import Travel from "./pages/Travel";
import RoyalCremation from "./pages/RoyalCremation";
import PhraPisalVisalo from "./pages/PhraPisalVisalo";
import Studio from "./pages/Studio";
import Interview from "./pages/Interview";
import AjarnPuey from "./pages/AjarnPuey";
import Commercial from "./pages/Commercial";
import Wedding from "./pages/Wedding";

import { Profile } from "./pages/Profile";
import { BrowserRouter, Route } from "react-router-dom";
import { connect } from "react-redux";

class _App extends React.Component {
  render() {
    return (
      <div>
        <BrowserRouter>
          <Route path="/" exact component={Homepage} />
          <Route path="/profile" exact component={Profile} />
          <Route path="/migrantPage" exact component={MigrantPage} />
          <Route path="/waterManagement" exact component={WaterManagement} />
          <Route path="/tearSheet" exact component={Tearsheets} />
          <Route path="/photojournalism" exact component={Photojournalism} />
          <Route path="/primaryCare" exact component={PrimaryCare} />
          <Route path="/corporate" exact component={Corporate} />
          <Route path="/portrait" exact component={Portrait} />
          <Route path="/travel" exact component={Travel} />
          <Route path="/royalCremation" exact component={RoyalCremation} />
          <Route path="/phraPisalVisalo" exact component={PhraPisalVisalo} />
          <Route path="/studio" exact component={Studio} />
          <Route path="/interview" exact component={Interview} />
          <Route path="/ajarnPuey" exact component={AjarnPuey} />
          <Route path="/commercial" exact component={Commercial} />
          <Route path="/wedding" exact component={Wedding} />
        </BrowserRouter>
      </div>
    );
  }
}

const mapStateToProps = (state: any) => {
  //console.log("App state: ", state);
  return {
    captions: state.captions,
  };
};

export const App = connect(mapStateToProps, {})(_App);
