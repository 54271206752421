import React, { createRef } from "react";
import FetchPhotosNew from "../component/FetchData/FetchPhotosNew";
import { ThumbnailPhoto } from "../component/ThumbnailPhoto";
import AccordionMenu from "../component/Menu/AccordionMenu";
import { Slider } from "../component/CarouselDisplay/Slider";
import { TopBar } from "../component/Elements";
import { primaryCareIntro } from "./Desc";
import { connect } from "react-redux";
import { Ref, Sticky } from "semantic-ui-react";
import "../App.css";

class PrimaryCare extends React.Component {
  state = { isThumbnail: true };
  contextRef = createRef();

  displayThumbnail = () => {
    this.setState({ isThumbnail: true });
  };

  displaySlider = () => {
    this.setState({ isThumbnail: false });
  };

  render() {
    const photoProps = {
      bucket: "PrimaryCareCluster",
      photos: this.props.primarycarecluster,
    };

    return (
      <Ref innerRef={this.contextRef}>
        <div>
          {this.props.primarycarecluster === null ? (
            <FetchPhotosNew bucket="PrimaryCareCluster" />
          ) : null}
          <Sticky context={this.contextRef}>
            <TopBar />
          </Sticky>
          <div className="photoPage">
            <div className="alignTitleAndMenuIcon">
              <AccordionMenu />
              <h3 className="photoTitle">Primary Care Cluster 4.0</h3>
            </div>
            <p className="photoDesc">{primaryCareIntro}</p>
            <div className="photoContainer">
              <i
                className="th icon iconCursor"
                onClick={() => this.displayThumbnail()}
              ></i>
              <i
                className="images outline icon iconCursor"
                onClick={() => this.displaySlider()}
              ></i>
              {this.state.isThumbnail &&
                this.props.primarycarecluster != null && (
                  <ThumbnailPhoto
                    {...photoProps}
                    animationName="redManRuningAnimation"
                  />
                )}
              {!this.state.isThumbnail &&
                this.props.primarycarecluster != null && (
                  <Slider {...photoProps} />
                )}
            </div>
          </div>
        </div>
      </Ref>
    );
  }
}

const mapStateToProps = (state) => {
  //console.log("PrimaryCare state: ", state);
  return state;
};

export default connect(mapStateToProps)(PrimaryCare);
