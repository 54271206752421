import React from "react";
import { facebookURL, instagramURL, email, linkedin } from "../../pages/Desc";
import "./index.css";

export const TopBar = () => {
  const facebookClicked = () => window.open(facebookURL);
  const instagramClicked = () => window.open(instagramURL);
  const emailClicked = () => window.open(`mailto:${email}`);
  const linkedinClicked = () => window.open(linkedin);

  return (
    <div className="headlineContainer">
      <div>
        <h3>Sittichai Jittatad</h3>
      </div>
      <div className="logos">
        <div>
          <i
            className="large facebook square icon iconCursor"
            onClick={facebookClicked}
          />
          <i
            className="large instagram icon iconCursor"
            onClick={instagramClicked}
          />
        </div>
        <div>
          <i
            className="large linkedin icon iconCursor"
            onClick={linkedinClicked}
          />
          <i
            className="large envelope outline icon iconCursor"
            onClick={emailClicked}
          />
        </div>
      </div>
    </div>
  );
};
