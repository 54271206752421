import React from "react";
import { PhotoPage } from "../component/Elements/PhotoPage";

const Studio = () => {
  return (
    <PhotoPage
      imageBucket="Studio"
      pageTitle="Studio"
      animationName="manRunningAnimation"
    />
  );
};

export default Studio;
