import React from "react";
import FetchPhotos from "../component/FetchData/FetchPhotos";
import FetchPhotosNew from "../component/FetchData/FetchPhotosNew";
import Lottie from "react-lottie";
import runningData from "../lotties/redManRunning.json";
import { TopBar, Footer } from "../component/Elements";
import { useSelector } from "react-redux";
import { ImageSlider } from "../component/ImageSlider";
import { MainMenuBar } from "../component/Menu/MainMenuBar";
import "../App.css";

const Homepage = () => {
  const homepage = useSelector((state) => state.landingpage);

  // This is just to have a custom height and width
  const displayLoadingAnimation = () => {
    const runningOptions = {
      loop: true,
      autoplay: true,
      animationData: runningData,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
    };

    return <Lottie options={runningOptions} height={100} width={120} />;
  };

  return (
    <div>
      {homepage === null ? <FetchPhotosNew bucket="LandingPage" /> : null}
      <TopBar />
      <div className="homepageContentContainer">
        <div className="homepageSideMenu">
          <MainMenuBar />
        </div>
        <div className="homepageSlide">
          {homepage != null ? (
            <ImageSlider photos={homepage} />
          ) : (
            <div className="homepageLoadingAnimation">
              {displayLoadingAnimation()}
            </div>
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Homepage;
