import { Dispatch } from "redux";
import { ActionTypes } from "./types";
import firebase from "../util/firebase";

export interface ICaptions {
  key: string;
  value: string;
}

export interface IImages {
  code: string;
  order: string;
  caption?: string;
  value: string;
}

export const fetchImageCaptions = () => {
  // console.log(`fetchImageCaptions called`);
  return async (dispatch: Dispatch) => {
    const captions: ICaptions[] = [];
    const ref = firebase.firestore().collection("captions");
    const snapshot = await ref.get();

    snapshot.forEach((doc) => {
      const photoId = doc.id;
      const caption = doc.get("value");
      // console.log(photoId, ":", caption);
      captions.push({ key: photoId, value: caption });
    });

    dispatch({
      type: ActionTypes.fetchCaptions,
      payload: captions,
    });
  };
};

// This is Action Creator
export const fetchImages = (bucket: string) => {
  // console.log(`fetchImages called. Bucket is ${bucket}`);
  return async (dispatch: Dispatch) => {
    const images: IImages[] = [];
    const storage = firebase.storage();
    var result = await storage.ref(bucket);
    // var photoLength = (await result.listAll()).items.length;
    // console.log(`result: ${result}`);

    result.listAll().then((res) => {
      // console.log(`res: ${res.items.length}`);
      if (res.items.length === 0) {
        dispatch({
          type: mapImagesToActionType(bucket),
          payload: images,
        });
      } else {
        res.items.forEach((item) => {
          // console.log(`item: ${item}`);
          item.getDownloadURL().then((url) => {
            const key = item.name;
            const order = key.slice(0, key.indexOf("_"));
            const code = key.slice(0, key.indexOf("."));

            // omit caption as this field is optional. The outcome will be caption: null
            images.push({
              code: code,
              order: order,
              value: url,
            });

            if (images.length === res.items.length) {
              // TODO: sort string
              // https://stackoverflow.com/questions/6712034/sort-array-by-firstname-alphabetically-in-javascript
              images.sort(function (a, b) {
                if (a.order < b.order) {
                  return -1;
                }
                if (a.order > b.order) {
                  return 1;
                }
                return 0;
              });
              // console.log(`loaded with images ${JSON.stringify(images)}`);

              // This is Action
              // Dispatch an Action, sending it Action to Reducer
              // Reducer updates the state base on the type and additional data provided by action.
              dispatch({
                type: mapImagesToActionType(bucket),
                payload: images,
              });
            }
          });
        });
      }
    });
  };
};

const mapImagesToActionType = (bucket: string) => {
  const lowerCaseBucket = bucket.toLowerCase();
  // console.log(`lowerCaseBucket ${lowerCaseBucket}`);
  switch (lowerCaseBucket) {
    case "myanmarmigrants":
      return ActionTypes.fetchMigrant;
    case "tearsheets":
      return ActionTypes.fetchTearSheet;
    case "primarycarecluster":
      return ActionTypes.fetchPrimaryCare;
    case "corporate":
      return ActionTypes.fetchCorporate;
    case "watermanagement":
      return ActionTypes.fetchWaterManagement;
    case "ajarnpuey":
      return ActionTypes.fetchAjarnPuey;
    case "photojournalism":
      return ActionTypes.fetchPhotojournalism;
    case "portrait":
      return ActionTypes.fetchPortrait;
    case "travel":
      return ActionTypes.fetchTravel;
    case "royalcremation":
      return ActionTypes.fetchRoyalCremation;
    case "phrapaisal":
      return ActionTypes.fetchPhraPaisal;
    case "studio":
      return ActionTypes.fetchStudio;
    case "interview":
      return ActionTypes.fetchInterview;
    case "landingpage":
      return ActionTypes.fetchLandingPage;
    case "commercial":
      return ActionTypes.fetchCommercialPage;
    case "wedding":
      return ActionTypes.fetchWeddingPage;
    default:
      return null;
  }
};

// TODO
// const assignCaptionToImage = () => {};

// Old functions
// export const fetchCaptions = () => {
//   return async (dispatch: Dispatch) => {
//     const db = firebase.firestore();
//     const response: ICaptions[] = [];

//     await db
//       .collection("captions")
//       .get()
//       .then((snapshot) => {
//         snapshot.docs.forEach((doc) => {
//           response.push({ key: doc.id, value: doc.data().text });
//         });
//       });

//     dispatch({
//       type: ActionTypes.fetchCaptions,
//       payload: response,
//     });
//   };
// };

// export const fetchPhotos = (bucket: string, photos: any) => {
//   switch (bucket) {
//     case "migrant":
//       return {
//         type: ActionTypes.fetchMigrant,
//         payload: photos,
//       };
//     case "tearsheet":
//       return {
//         type: ActionTypes.fetchTearSheet,
//         payload: photos,
//       };
//     case "primarycare":
//       return {
//         type: ActionTypes.fetchPrimaryCare,
//         payload: photos,
//       };
//     case "corporate":
//       return {
//         type: ActionTypes.fetchCorporate,
//         payload: photos,
//       };
//     case "waterManagement":
//       return {
//         type: ActionTypes.fetchWaterManagement,
//         payload: photos,
//       };
//     case "ajarnPuey":
//       return {
//         type: ActionTypes.fetchAjarnPuey,
//         payload: photos,
//       };
//     case "photojournalism":
//       return {
//         type: ActionTypes.fetchPhotojournalism,
//         payload: photos,
//       };
//     case "portrait":
//       return {
//         type: ActionTypes.fetchPortrait,
//         payload: photos,
//       };
//     case "travel":
//       return {
//         type: ActionTypes.fetchTravel,
//         payload: photos,
//       };
//     case "royalCremation":
//       return {
//         type: ActionTypes.fetchRoyalCremation,
//         payload: photos,
//       };
//     case "phraPaisal":
//       return {
//         type: ActionTypes.fetchPhraPaisal,
//         payload: photos,
//       };
//     case "studio":
//       return {
//         type: ActionTypes.fetchStudio,
//         payload: photos,
//       };
//     case "interview":
//       return {
//         type: ActionTypes.fetchInterview,
//         payload: photos,
//       };
//     case "landingPage":
//       return {
//         type: ActionTypes.fetchLandingPage,
//         payload: photos,
//       };
//     case "commercial":
//       return {
//         type: ActionTypes.fetchCommercialPage,
//         payload: photos,
//       };
//     case "wedding":
//       return {
//         type: ActionTypes.fetchWeddingPage,
//         payload: photos,
//       };
//     default:
//       return null;
//   }
// };
