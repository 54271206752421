import React from "react";
import Lottie from "react-lottie";
import cameraData from "../../../lotties/camera.json";

const CameraAnimation = () => {
  const cameraLoaderOptions = {
    loop: true,
    autoplay: true,
    animationData: cameraData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <Lottie
      style={{ marginTop: "50px" }}
      options={cameraLoaderOptions}
      height={100}
      width={300}
    />
  );
};

export default CameraAnimation;
