import React, { useState, useEffect } from "react";
import Masonry from "react-masonry-component";
import PhotoModal from "../PhotoModal/index";
import ManRuningAnimation from "../Elements/Animations/RunningAnimation";
import RedManRuningAnimation from "../Elements/Animations/RedManRunningAnimation";
import CameraAnimation from "../Elements/Animations/CameraAnimation";
import { Footer } from "../Elements";
import "./index.css";

export const ThumbnailPhoto = ({ photos, animationName }) => {
  const [photoLoaded, setPhotoLoaded] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedPhoto, setSelectedPhoto] = useState("");
  const [caption, setCaption] = useState("");
  const [code, setCode] = useState("");

  // disable right click on web
  useEffect(() => {
    document.addEventListener("contextmenu", (e) => {
      e.preventDefault();
    });
  });

  const startDisplaySomePhotos = () => {
    setPhotoLoaded(true);
  };

  const handlePhotoLoaded = (key) => {
    const allPhotosCount = photos.length;
    const arbituaryCount = allPhotosCount > 8 ? 8 : allPhotosCount;
    //const allPhotosCount = this.props.photos.length / 2
    //console.log(`loaded photo count ${key}`)
    //console.log(`length ${allPhotosCount}`)
    const loadedCount = key + 1;
    if (loadedCount === Math.round(arbituaryCount)) {
      startDisplaySomePhotos();
    }
  };

  const handleCloseModal = (value) => {
    setIsModalOpen(!value);
  };

  const displayPhotoModal = () => {
    return (
      <PhotoModal
        isClose={handleCloseModal}
        selectedPhoto={selectedPhoto}
        caption={caption}
        code={code}
      />
    );
  };

  const handleShowModal = (photo) => {
    setIsModalOpen(true);
    setSelectedPhoto(photo.value);
    setCaption(photo.caption);
    setCode(photo.code);
  };

  const renderThumbnailPhotos = (photos) => {
    photos.sort((a, b) => a.order - b.order);
    return (
      <>
        {photos.map((photo, key) => (
          <div className="imageContainer" key={key}>
            <img
              className="singleImage"
              src={photo.value}
              alt="Not found."
              onClick={() => handleShowModal(photo)}
              onMouseDown={(e) => e.preventDefault()}
              onLoad={() => handlePhotoLoaded(key)}
              style={photoLoaded ? {} : { display: "none" }}
            />
          </div>
        ))}
      </>
    );
  };

  const displayThumbnails = (photos) => {
    return (
      <Masonry className="thumbnail">{renderThumbnailPhotos(photos)}</Masonry>
    );
  };

  const displayAnimation = () => {
    switch (animationName) {
      case "redManRuningAnimation":
        return <RedManRuningAnimation />;
      case "manRunningAnimation":
        return <ManRuningAnimation />;
      case "cameraAnimation":
        return <CameraAnimation />;
      default:
        return;
    }
  };

  return (
    <div style={{ marginTop: "50px" }}>
      {photos != null && displayThumbnails(photos)}
      {photoLoaded ? <Footer /> : displayAnimation()}
      {isModalOpen && displayPhotoModal()}
    </div>
  );
};
