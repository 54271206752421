import React from "react";
import { PhotoPage } from "../component/Elements/PhotoPage";

const RoyalCremation = () => {
  return (
    <PhotoPage
      imageBucket="RoyalCremation"
      pageTitle="King Rama IX's Royal Cremation"
      animationName="redManRuningAnimation"
    />
  );
};

export default RoyalCremation;
