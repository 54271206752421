import React, { createRef } from "react";
import FetchPhotosNew from "../component/FetchData/FetchPhotosNew";
import { ThumbnailPhoto } from "../component/ThumbnailPhoto";
import { connect } from "react-redux";
import AccordionMenu from "../component/Menu/AccordionMenu";
import { TopBar } from "../component/Elements";
import { migrantIntro } from "./Desc";
import { Slider } from "../component/CarouselDisplay/Slider";
import { Ref, Sticky } from "semantic-ui-react";
import "../App.css";

class MigrantPage extends React.Component {
  state = { isThumbnail: true };
  contextRef = createRef();

  displayThumbnail = () => {
    this.setState({ isThumbnail: true });
  };

  displaySlider = () => {
    this.setState({ isThumbnail: false });
  };

  render() {
    const photoProps = {
      bucket: "MyanmarMigrants",
      photos: this.props.myanmarmigrants,
    };

    return (
      <Ref innerRef={this.contextRef}>
        <div>
          {this.props.myanmarmigrants === null ? (
            <FetchPhotosNew bucket="MyanmarMigrants" />
          ) : null}
          <Sticky context={this.contextRef}>
            <TopBar />
          </Sticky>
          <div className="photoPage">
            <div className="alignTitleAndMenuIcon">
              <AccordionMenu />
              <h3 className="photoTitle">
                Myanmar Migrant Workers in Thailand
              </h3>
            </div>
            <p className="photoDesc">{migrantIntro}</p>
            <div className="photoContainer">
              <i
                className="th icon iconCursor"
                style={{ marginRight: "10px" }}
                onClick={() => this.displayThumbnail()}
              ></i>
              <i
                className="images outline icon iconCursor"
                onClick={() => this.displaySlider()}
              ></i>
              {this.state.isThumbnail && this.props.myanmarmigrants != null && (
                <ThumbnailPhoto
                  {...photoProps}
                  animationName="manRunningAnimation"
                />
              )}
              {!this.state.isThumbnail &&
                this.props.myanmarmigrants != null && (
                  <Slider {...photoProps} />
                )}
            </div>
          </div>
        </div>
      </Ref>
    );
  }
}

const mapStateToProps = (state) => {
  // console.log("migrant state: ", state);
  return state;
};

export default connect(mapStateToProps)(MigrantPage);
