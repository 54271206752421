export enum ActionTypes {
  fetchCaptions,
  fetchImages,
  fetchMigrant,
  fetchTearSheet,
  fetchPrimaryCare,
  fetchWaterManagement,
  fetchCorporate,
  fetchAjarnPuey,
  fetchPhotojournalism,
  fetchPortrait,
  fetchTravel,
  fetchRoyalCremation,
  fetchPhraPaisal,
  fetchStudio,
  fetchInterview,
  fetchLandingPage,
  fetchCommercialPage,
  fetchWeddingPage,
}
