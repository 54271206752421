import React from "react";
import { Button, Image, Modal, Icon } from "semantic-ui-react";
import "./index.css";

export default class PhotoModal extends React.Component {
  handleCloseModal = () => {
    this.props.isClose(true);
  };

  handleMouseLeave = (e) => {
    this.props.isClose(true);
    // console.log("mouse leaveing");
  };

  render() {
    const photo = this.props.selectedPhoto;
    const caption = this.props.caption;
    const code = this.props.code != null ? `Code: ${this.props.code}` : "";
    return (
      <Modal
        basic
        open={true}
        size="small"
        onMouseLeave={this.handleMouseLeave}
      >
        <Modal.Content image>
          <Image size="massive" src={photo} wrapped />
        </Modal.Content>
        <p className="modalCaption">{code}</p>
        <p className="modalCaption">{caption}</p>
        <Modal.Actions>
          <Button
            style={{ display: "flex", margin: "auto" }}
            basic
            color="red"
            inverted
            onClick={() => this.handleCloseModal()}
          >
            <Icon name="remove" /> Close
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}
