import React from "react";
import "./index.css";

export const Footer = () => (
  <div className="footer">
    <p>
      All rights reserved <i className="copyright outline icon" />
      Sittichai Jittatad{" "}
    </p>
  </div>
);
