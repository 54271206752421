import React from "react";
import { PhotoPage } from "../component/Elements/PhotoPage";

const TearSheet = () => {
  return (
    <PhotoPage
      imageBucket="tearsheets"
      pageTitle="Tearsheets"
      animationName="cameraAnimation"
    />
  );
};

export default TearSheet;
