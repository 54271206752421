import React from "react";
import { PhotoPage } from "../component/Elements/PhotoPage";

const WaterManagement = () => {
  return (
    <PhotoPage
      imageBucket="WaterManagement"
      pageTitle="Water Management"
      animationName="manRunningAnimation"
    />
  );
};

export default WaterManagement;
