import React from "react";
import { PhotoPage } from "../component/Elements/PhotoPage";

const PhraPisalVisalo = () => {
  return (
    <PhotoPage
      imageBucket="PhraPaisal"
      pageTitle="Phra Paisal Visalo"
      animationName="redManRuningAnimation"
    />
  );
};

export default PhraPisalVisalo;
