import AwesomeSlider from "react-awesome-slider";
import React from "react";
import { Footer } from "../Elements/Footer";
import AwesomeSliderStyles from "react-awesome-slider/dist/styles.css";
import withAutoplay from "react-awesome-slider/dist/autoplay";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./index.css";

export const Slider = ({ photos }) => {
  const AutoPlaySlider = withAutoplay(AwesomeSlider);

  return (
    <>
      {photos !== null && (
        <div>
          <AutoPlaySlider
            play={true}
            style={AwesomeSliderStyles}
            cancelOnInteraction={false}
            interval={5000}
            bullets={false}
            className="customSlider"
          >
            {photos.map((photo, key) => (
              <div key={key}>
                <p className="photoNumber">
                  {key + 1} of {photos.length}
                </p>
                <img
                  className="slideImage"
                  src={photo.value}
                  key={key}
                  alt="Not found."
                />
                <p style={{ fontSize: "small" }}>{photo.caption}</p>
              </div>
            ))}
          </AutoPlaySlider>
          <Footer />
        </div>
      )}
    </>
  );
};
