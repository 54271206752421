import React from "react";
import { PhotoPage } from "../component/Elements/PhotoPage";

const Travel = () => {
  return (
    <PhotoPage
      imageBucket="Travel"
      pageTitle="Travel"
      animationName="redManRuningAnimation"
    />
  );
};

export default Travel;
