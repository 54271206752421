import React, { useState, createRef } from "react";
import CommingSoonAnimation from "./Animations/CommingSoonAnimation";
import FetchPhotosNew from "../FetchData/FetchPhotosNew";
import { useSelector } from "react-redux";
import { TopBar } from ".";
import { ThumbnailPhoto } from "../ThumbnailPhoto";
import AccordionMenu from "../Menu/AccordionMenu";
import { Slider } from "../CarouselDisplay/Slider";
import { Ref, Sticky } from "semantic-ui-react";

// All pages excluding Homepage and other class-based component examples
export const PhotoPage = ({ imageBucket, pageTitle, animationName }) => {
  const contextRef = createRef();
  const [isThumbnail, setIsThumbnail] = useState(true);

  // Alternative way to access props
  // const { imageBucket, pageTitle, animationName } = props;

  // Get images from State Store
  const images = useSelector((state) => {
    if (imageBucket) {
      switch (imageBucket) {
        case "Portrait":
          return state.portrait;
        case "tearsheets":
          return state.tearsheet;
        case "Photojournalism":
          return state.photojournalism;
        case "PrimaryCareCluster":
          return state.primarycarecluster;
        case "MyanmarMigrants":
          return state.migrants;
        case "Corporate":
          return state.corporate;
        case "Travel":
          return state.travel;
        case "PhraPaisal":
          return state.phrapaisal;
        case "Studio":
          return state.studio;
        case "Interview":
          return state.interview;
        case "AjarnPuey":
          return state.ajarnpuey;
        case "RoyalCremation":
          return state.royalcremation;
        case "WaterManagement":
          return state.watermanagement;
        case "Wedding":
          return state.wedding;
        case "Commercial":
          return state.commercial;
        default:
          break;
      }
    }
  });

  const toDisplayThumbnail = () => {
    setIsThumbnail(true);
  };

  const toDisplaySlider = () => {
    setIsThumbnail(false);
  };

  const displayThumbnail = () => {
    return <ThumbnailPhoto photos={images} animationName={animationName} />;
  };

  const displaySlider = () => {
    return <Slider photos={images} />;
  };

  const displayCommingSoonAnimation = () => {
    return <CommingSoonAnimation />;
  };

  return (
    <Ref innerRef={contextRef}>
      <div>
        {images === null ? <FetchPhotosNew bucket={imageBucket} /> : null}
        <Sticky context={contextRef}>
          <TopBar />
        </Sticky>
        <div className="photoPage">
          <div className="alignTitleAndMenuIcon">
            <AccordionMenu />
            <h3 className="photoTitle">{pageTitle}</h3>
          </div>
          <p className="photoDesc"></p>
          <div className="photoContainer">
            <i
              className="th icon iconCursor"
              onClick={() => toDisplayThumbnail()}
            ></i>
            <i
              className="images outline icon iconCursor"
              onClick={() => toDisplaySlider()}
            ></i>
            {images != null &&
              Object.keys(images).length === 0 &&
              displayCommingSoonAnimation()}
            {isThumbnail &&
              images != null &&
              Object.keys(images).length > 0 &&
              displayThumbnail()}
            {!isThumbnail &&
              images != null &&
              Object.keys(images).length > 0 &&
              displaySlider()}
          </div>
        </div>
      </div>
    </Ref>
  );
};
